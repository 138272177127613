export const state = () => ({
  token: null,
})

export const mutations = {
  setToken(state, token) {
    state.token = token
  },
  logout() {
    this.$router.push('/login')
    this.commit('user/setToken', null)
  },
}
