import createPersistedState from 'vuex-persistedstate'

export default ({ store, req, app }) => {
  createPersistedState({
    key: 'nuxt-etl',
    paths: ['user'],
    storage: {
      getItem(key) {
        const el = app.$cookies.get(key, {
          parseJSON: false,
        })
        return el
      },
      setItem(key, value) {
        app.$cookies.set(key, value, {
          maxAge: 60 * 60 * 24 * 7,
          secure: process.env.production,
          path: '/',
        })
      },
      removeItem: (key) =>
        app.$cookies.remove(key, {
          path: '/',
        }),
    },
  })(store)
}
