export const CopyText = () => import('../../components/CopyText/CopyText.vue' /* webpackChunkName: "components/copy-text" */).then(c => wrapFunctional(c.default || c))
export const ETLForm = () => import('../../components/ETLForm/ETLForm.vue' /* webpackChunkName: "components/e-t-l-form" */).then(c => wrapFunctional(c.default || c))
export const Editor = () => import('../../components/Editor/Editor.vue' /* webpackChunkName: "components/editor" */).then(c => wrapFunctional(c.default || c))
export const InfoBlock = () => import('../../components/InfoBlock/InfoBlock.vue' /* webpackChunkName: "components/info-block" */).then(c => wrapFunctional(c.default || c))
export const Instruction = () => import('../../components/Instruction/Instruction.vue' /* webpackChunkName: "components/instruction" */).then(c => wrapFunctional(c.default || c))
export const Unloading = () => import('../../components/Unloading/Unloading.vue' /* webpackChunkName: "components/unloading" */).then(c => wrapFunctional(c.default || c))
export const DataEntity = () => import('../../data/DataEntity.vue' /* webpackChunkName: "components/data-entity" */).then(c => wrapFunctional(c.default || c))
export const ActionEntity = () => import('../../action/ActionEntity.vue' /* webpackChunkName: "components/action-entity" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
