//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Button, StatusBlock } from '05-ui-kit'
import { mapState } from 'vuex'

export default {
  middleware: ['tokenCheck'],
  name: 'DefaultLayout',
  components: {
    Button,
    StatusBlock,
  },
  computed: {
    ...mapState({
      templates: (state) => state?.template?.templates || [],
    }),
  },
  methods: {
    onSuccess({ response }) {
      this.$store.commit('template/setTemplates', response)
    },
    exit() {
      this.$store.commit('user/logout')
    },
  },
}
