export default {
  async login(token) {
    const result = await this.$axios.$post('/auth', {
      password: token,
    })
    return {
      result,
    }
  },
}
