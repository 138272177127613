import { render, staticRenderFns } from "./default.vue?vue&type=template&id=141470c4&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=141470c4&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "141470c4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Unloading: require('/app/components/Unloading/Unloading.vue').default,DataEntity: require('/app/data/DataEntity.vue').default})
