export function serializeQueryParams(obj = {}, { delimiter = '&' } = {}) {
  if (Object.keys(obj).length === 0) {
    return ''
  }
  let query = '?'
  for (const prop in obj) {
    if (Array.isArray(obj[prop])) {
      obj[prop].forEach((el) => {
        query += `${prop}=${el}${delimiter}`
      })
    } else if (obj[prop]) {
      query += `${prop}=${encodeURIComponent(obj[prop])}${delimiter}`
    }
  }
  return query.slice(0, -1) // crop the last char, it's a unnecessary delimiter
}
