export const state = () => ({
  templates: null,
})

export const mutations = {
  setTemplates(state, data) {
    state.templates = data
  },
  pushTemplate(state, template) {
    if (!Array.isArray(state.templates)) {
      state.templates = []
    }
    state.templates.push(template)
  },
  removeTemplate(state, id) {
    state.templates = state.templates.filter((template) => {
      return template.id !== id
    })
  },
}
