import { serializeQueryParams } from './helper'

export default {
  async get({ provider, tag, onlyactive, format } = {}) {
    const result = await this.$axios.$get(
      `/templates${serializeQueryParams({
        provider,
        tag,
        onlyactive,
        format,
      })}`
    )
    return result
  },
  async getByID({ id } = {}) {
    const result = await this.$axios.$get(
      `/templates${serializeQueryParams({
        id,
      })}`
    )
    const formFields = await this.$axios.$get('/availablefromfields')
    return {
      result: result?.[0],
      formFields,
    }
  },
  async create(data = {}) {
    const body = JSON.stringify(data)
    const result = await this.$axios.$post('/template', body)
    return result
  },
  async put(data = {}) {
    const body = JSON.stringify(data)
    const result = await this.$axios.$put('/template', body, {
      headers: {
        'content-type': 'application/json',
      },
    })
    return result
  },
  remove({ id } = {}) {
    return this.$axios.$delete(`/template/${id}`)
  },
  getFileByID({ id } = {}) {
    return this.$axios.$get(`/template/files/${id}`)
  },
}
