import generateApi from '~/api'

export default (context, inject) => {
  if (context?.store?.state?.user?.token) {
    context.app.$axios.setToken(context?.store?.state?.user?.token)
  }

  context.app.$axios.onError((error) => {
    if (error.response?.status === 401) {
      console.error(`Ошибка ${error}`)
    } else {
      throw error
    }
  })

  inject('api', generateApi(context))
}
