/* Закомментировал, пока не работает авторизация */
import editor from './editor.api'
import auth from './auth.api'
import template from './template.api'
import log from './log.api'

const mapBindMethods = (context, methods) => {
  const obj = {}
  Object.keys(methods).forEach((key) => {
    obj[key] = methods[key].bind(context)
  })
  return obj
}

export default (context) => ({
  editor: mapBindMethods(context, editor),
  log: mapBindMethods(context, log),
  auth: mapBindMethods(context, auth),
  template: mapBindMethods(context, template),
})
