/**
 * Базовый функционал для умных компонентов, содержит внутри себя пайплайн для
 * прохождения запроса и переключения статусов запроса
 */

export default {
  data() {
    return {
      /**
       * Все свойства объекта externalAPI попадут в slot-scope компонента при его
       * вызове, но сюда нельзя класть функции, для их передачи через slot-scope используется отдельный метод appendMethodToAPI
       */
      externalAPI: {
        status: 'initial',
        statusCode: null,
        initialLoad: this.initialLoad,
        initial: true,
        loading: this.loading,
        success: this.success,
        error: this.error,
      },
    }
  },
  created() {
    /**
     * appendMethodToAPI нужно вызывать в хуке created, чтобы передать нужные функции через slot-scope
     */
    this.appendMethodToAPI({ name: 'changeStatus', fn: this.changeStatus })
  },
  methods: {
    /**
     * Сохранять функции в объекте externalAPI нельзя т.к. накст пытается
     * их сериализовать при передаче на клиент и из функций делает объекты, поэтому для передачи
     * функций в slot-scope компонента при вызове мы используем этот метод
     * @param {string} obj.name - название функции
     * @param {function} obj.fn - функция, которая будет вызвана
     */
    appendMethodToAPI({ name, fn } = {}) {
      if (!this._api) this._api = {}
      this._api[name] = fn
    },
    /**
     * Ручная смена статуса
     * @param {string} status - новый статус: initial, error, loading или success
     */
    changeStatus(status, value) {
      this.resetStatus()
      this.externalAPI[status] = value || true
      this.externalAPI.status = status
    },
    resetStatus() {
      this.externalAPI.initial = false
      this.externalAPI.loading = false
      this.externalAPI.success = false
      this.externalAPI.error = false
    },
    /**
     * Основной пайплайн для запроса, на разных этапах меняем статус, вызываем каллбэки
     */
    async request({
      requestStrategy = 'first-request-priority',
      api = () => Promise.resolve(),
      onLoad = () => {},
      onSuccess = () => {},
      onError = () => {},
      replaceCurrentLoading = false,
    } = {}) {
      if (
        !replaceCurrentLoading &&
        requestStrategy === 'first-request-priority' &&
        this.externalAPI.loading
      )
        return
      onLoad()
      this.changeStatus('loading')
      this.$emit(`loading`, { ...this.externalAPI, ...this._api })
      try {
        const response = await api()
        this.externalAPI.statusCode = 200
        onSuccess(response)
        this.changeStatus('success')
        this.$emit(`success`, { ...this.externalAPI, ...this._api })
      } catch (error) {
        /**
         * Если запрос был отменён через CancelToken потому что есть второй запрос
         * то включаем статус загрузки и ждём завершение второго запроса
         */
        if (
          requestStrategy === 'last-request-priority' &&
          this.$axios.isCancel(error)
        ) {
          return this.changeStatus('loading')
        }

        console.error('Ошибка в умном компоненте', error)
        this.externalAPI.statusCode = error?.response?.status || 520
        if ([520, 424, 404].some((v) => v === this.externalAPI.statusCode)) {
          this.changeStatus('error', 'not_found')
        } else {
          this.changeStatus('error')
        }
        onError(error)
        this.$emit(`error`, { ...this.externalAPI, ...this._api })
      }
    },
  },
  /**
   * у самого компонента нет тимплейта вообще и он прокидывает тот слот который в него передан внутрь себя прям на первый
   * уровень т.е. то что в слоте на первом уровне, то и на первом уровне внутри компонента. Написано это для того, чтобы
   * умные компоненты своими тегами не влияли никак на структуру и были такими псевдо компонентами
   */
  render(h) {
    return typeof this.$scopedSlots.default === 'function'
      ? this.$scopedSlots.default({ ...this.externalAPI, ...this._api })
      : h('span')
  },
}
